@supports not (-webkit-touch-callout: none) {
	@keyframes fadeIn {
		0% {
			opacity: 0%;
			transform: scale(150%);
			filter: blur(40px);
		}
		100% {
			opacity: 100%;
			transform: scale(100%);
			filter: blur(0px);
		}
	}
}

@supports (-webkit-touch-callout: none) {
	@keyframes fadeIn {
		0% {
			opacity: 0%;
			transform: scale(150%);
		}
		100% {
			opacity: 100%;
			transform: scale(100%);
		}
	}
}

@keyframes buttons {
	0% {
		visibility: collapse;
		opacity: 0;
	}
	1% {
		opacity: 0;
		visibility: visible;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}

body {
	color: #eee;
	background-color: black;
}

.centered {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.content {
  opacity: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 496px;
	max-height: 100%;
  padding: 24px;
  transition: height 1s ease-in-out;
  animation-duration: 4s;
  animation-timing-function:cubic-bezier(0.19, 1, 0.22, 1);
  animation-name: fadeIn;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
	will-change: backdrop-filter;
}

.photo {
  width: 100%;
	object-fit: contain;
}

.name {
	font-family: "Great Vibes", cursive;
	font-size: 64px;
	margin: -32px 0;
  font-weight: normal;
}

.dates {
	font-family: "Georgia", "Times New Roman", Times, serif;
	font-size: 24px;
}

.button {
  border: 1px solid #eee;
  background-color: transparent;
  color: #eee;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.2s;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
  font-size: 16px;
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.buttons {
  display: flex;
  gap: 16px;
	visibility: collapse;
	opacity: 0;
	align-items: center;
	animation-delay: 3s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-name: buttons;
	animation-duration: 1s;
}

@media only screen and (max-width: 600px) {
  .name { font-size: 48px; }
}

@media only screen and (max-width: 400px) {
  .name { font-size: 32px; }
}